import React from "react";

const Footer = (props) => (
  <footer id="footer">
    <div className="inner">
      <ul className="copyright">
        <li>&copy; ECCO Design Build</li>
        <li><a href="/privacy_policy">Privacy Policy</a></li>
        <li>
          Design: <a href="https://jpalmieri.com">jpalmieri</a> and{" "}
          <a href="https://html5up.net">HTML5 UP</a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
